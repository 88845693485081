import { useRoute } from '#imports'
import { ref, watch } from 'vue'

import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { transformReviewsResponse } from '../utils/transformReviewsResponse'

import { useRequests } from './useRequests'

function useFetchReviewsWithAttachments() {
  const route = useRoute()
  const requests = useRequests()
  const locale = useI18nLocale()

  const minRate = 4
  const maxAmount = 20
  const withAttachments = true

  if (!requests || !('getReviews' in requests))
    return { data: ref(), error: ref(), pending: ref(false) }

  const headers = 'headers' in requests ? requests.headers : undefined

  return useHttpFetch(requests.getReviews, {
    headers,
    pathParams: route.params,
    queryParams: {
      global_rate__gte: minRate,
      page_size: maxAmount,
      with_attachments: withAttachments,
      translation_locale: locale,
    },
    server: false,
    transform: ({ results }) => transformReviewsResponse(results),
    lazy: true,
  })
}

export function useReviewsWithAttachments() {
  const route = useRoute()
  const logger = useLogger()

  const { data, error, pending } = useFetchReviewsWithAttachments()

  watch(
    error,
    () => {
      if (error.value instanceof Error) {
        logger.error(`[REVIEWS][${route.name?.toString()}] With attachments`, {
          error: error.value,
          owners: ['bot-squad-content-front'],
        })
      }
    },
    { immediate: true },
  )

  return {
    reviewsWithAttachments: data,
    isPendingReviewsWithAttachments: pending,
  }
}
