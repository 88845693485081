import { useRoute } from '#imports'

import {
  landingReviewsAPI,
  productListPageAPI,
  productReviewsAPI,
  reviewsAPI,
  sellerReviewsAPI,
} from '@backmarket/http-api'
import { getSeoTags } from '@backmarket/http-api/src/api-specs-acquisition/link-booster/tags'
import { getMerchantShop } from '@backmarket/http-api/src/api-specs-navigation-experience/merchant-shop/merchant-shop'
import { getProduct } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import { PRODUCT } from '~/scopes/product/route-names'
import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import { ROUTES } from '../../route-names'

// TODO Use dedicated service to request the title once it has been created
export function useRequests() {
  const route = useRoute()

  switch (route.name) {
    case ROUTES.PAGES.PRODUCT:
    case PRODUCT.HOME:
      return {
        ...productReviewsAPI,
        headers: { 'BM-Page-Name': route.name },
        getTitle: getProduct,
        getSeoTags,
      }

    case ROUTES.PAGES.LANDING:
    case PRODUCT_LIST_ROUTES.PRODUCT_LIST:
      return {
        ...landingReviewsAPI,
        getTitle: productListPageAPI.getProductListPage,
        getSeoTags,
      }

    case ROUTES.PAGES.MERCHANT:
      return {
        ...sellerReviewsAPI,
        getTitle: getMerchantShop,
      }

    case ROUTES.PAGES.ALL:
      return reviewsAPI

    default:
      return undefined
  }
}
