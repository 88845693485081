import { useRoute } from '#imports'
import { computed, onMounted, ref, watch } from 'vue'

import type { RatingDistribution } from '@backmarket/http-api/src/api-specs-reviews/types/distributions'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useRequests } from '../../../composables/useRequests'
import translations from '../Filters.translations'

export function useRatingFilters() {
  const i18n = useI18n()
  const logger = useLogger()
  const route = useRoute()
  const requests = useRequests()

  const selectedRange = ref<string>()
  const ranges = ref<RatingDistribution[]>([])
  const loading = ref(true)

  const rangeTranslations: Record<string, string> = {
    rate_range_1: i18n(translations.oneTwo),
    rate_range_2: i18n(translations.twoThree),
    rate_range_3: i18n(translations.threeFour),
    rate_range_4: i18n(translations.fourFive),
  }

  const DEFAULT_RANGES: RatingDistribution[] = Object.keys(
    rangeTranslations,
  ).map((label, index) => {
    const regularIndex = index + 1

    return {
      range: {
        min: regularIndex,
        max: regularIndex + 1,
        label,
      },
      value: 0,
    }
  })

  const rangesList = computed(() => {
    const availableRanges = ranges.value?.length ? ranges.value : DEFAULT_RANGES

    const formattedRanges = availableRanges
      .map(({ range: { label, min, max }, value }) => ({
        disabled: value <= 0,
        label: rangeTranslations[label],
        min,
        max,
        selected: `${min}-${max}`,
        value,
      }))
      .reverse()

    formattedRanges.unshift({
      disabled: false,
      label: i18n(translations.all),
      min: 1,
      max: 5,
      selected: '1-5',
      value: 100,
    })

    return formattedRanges
  })

  onMounted(async () => {
    try {
      if (requests && 'getRatingDistribution' in requests) {
        ranges.value = await $httpFetch(requests.getRatingDistribution, {
          pathParams: route.params,
        })
      }
    } catch (error) {
      logger.error(`[REVIEWS][${route.name?.toString()}]`, {
        error: error instanceof Error ? error : new Error(''),
        owners: ['bot-squad-content-front'],
      })
    } finally {
      loading.value = false
    }
  })

  watch(
    () => route.query,
    () => {
      const { global_rate__gte: min, global_rate__lte: max } = route.query
      const current = rangesList.value.find(
        ({ selected }) => selected === `${min}-${max}`,
      )
      selectedRange.value = current?.selected || rangesList.value[0].selected
    },
    { immediate: true },
  )

  return {
    rangesList,
    selectedRange,
    loading,
  }
}
