import { useRoute } from '#imports'
import { ref, watch } from 'vue'

import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useRequests } from './useRequests'

function useFetchRating() {
  const route = useRoute()

  const requests = useRequests()

  if (!requests || !('getRating' in requests))
    return { data: ref(), error: ref() }

  return useHttpFetch(requests.getRating, {
    pathParams: route.params,
  })
}

export function useGlobalRating() {
  const route = useRoute()

  const logger = useLogger()

  const { data: ratesResponse, error } = useFetchRating()

  watch(
    error,
    () => {
      if (error.value instanceof Error) {
        logger.error(`[REVIEWS][${route.name?.toString()}]`, {
          error: error.value,
          owners: ['bot-squad-content-front'],
        })
      }
    },
    { immediate: true },
  )

  return {
    ratesResponse,
  }
}
