export default {
  alternativeNext: {
    id: 'reviews-photo-carousel-alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'reviews-photo-carousel-alternative-previous',
    defaultMessage: 'Previous',
  },
  title: {
    id: 'reviews-photo-carousel-title',
    defaultMessage: 'Reviews with pictures',
  },
} as const
