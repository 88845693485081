<template>
  <div>
    <RevInputSelect
      v-if="shouldDisplayInputSelect"
      :id
      class="w-full"
      :label="i18n(translations.sortBy)"
      :model-value="selectedSort"
      :options="list"
      @update:model-value="updateSelectedSort($event as string)"
    />

    <fieldset v-else :id>
      <h2 class="body-1-bold">
        {{ i18n(translations.sortBy) }}
      </h2>
      <ul>
        <RevListItemStatic
          v-for="option in list"
          :key="option.value"
          class="-mb-16"
        >
          <template #label>
            <RevRadio
              :id="option.value"
              :model-value="selectedSort === option.value"
              :name="id"
              :value="option.value"
              @update:model-value="updateSelectedSort($event as string)"
            >
              <template #label>
                <div class="body-1">{{ option.label }}</div>
              </template>
            </RevRadio>
          </template>
        </RevListItemStatic>
      </ul>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevInputSelect } from '@ds/components/InputSelect'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevRadio } from '@ds/components/Radio'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import { useGetOrderByParamValue } from '../../composables/useGetOrderByParamValue'
import { TRACKING_ZONE } from '../../constants'

import translations from './Sort.translations'
import { type RateSort } from './Sort.types'
import { getSortOptions } from './getSortOptions'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const { trackClick } = useTracking()
const orderBy = useGetOrderByParamValue()

const props = defineProps<{ type: RateSort; id: string }>()

const { list, defaultOption } = getSortOptions()

const selectedSort = ref((route.query?.order_by as string) || defaultOption)

const shouldDisplayInputSelect = computed(() => props.type === 'select')

function updateSelectedSort(sort: string) {
  selectedSort.value = sort

  const routeName = route.name?.toString() || ''

  trackClick({
    pageType: TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName],
    zone: TRACKING_ZONE,
    name: `review_star_sort_${sort}`,
  })

  router.push({
    ...route,
    query: {
      ...route.query,
      order_by: !isEmpty(orderBy) && sort === defaultOption ? orderBy : sort,
    },
  })
}
</script>
