<template>
  <div class="relative">
    <ul
      :class="[
        '-mb-16 list-none p-0',
        {
          'hidden gap-x-56 md:grid md:grid-cols-2 lg:grid-cols-3':
            !isCategoriesListDisplayed,
        },
      ]"
    >
      <li
        v-for="detail in summary"
        :key="detail.identifier"
        class="body-2 mb-16 flex items-center justify-between"
      >
        <div class="w-1/2 break-words pr-8">
          {{
            i18n(
              detailTitleByIdentifier[
                detail.identifier as ProductRatingDetailsIdentifierWithoutAttachments
              ],
            )
          }}
        </div>
        <div class="flex w-1/2 items-center">
          <ProgressBar
            class="mr-12 grow"
            :current-value="detail.averageRate"
            :max="MAX_RATING"
          />
          <div>{{ detail.averageRate.toFixed(1) }}/{{ MAX_RATING }}</div>
        </div>
      </li>
    </ul>
    <RevLink
      :class="[
        'body-2-bold flex items-center justify-center md:hidden',
        { 'pt-32': isCategoriesListDisplayed },
      ]"
      :tracking
      @click="toggleCategories"
    >
      {{ toggleCtaText }}

      <IconChevronDown
        :class="[
          { 'rotate-180 transition-transform': isCategoriesListDisplayed },
        ]"
      />
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import type {
  ProductRatingDetailsIdentifierWithoutAttachments,
  RatingDetail,
} from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { IconChevronDown } from '@ds/icons/IconChevronDown'

import { MAX_RATING, TRACKING_ZONE } from '../../constants'
import ProgressBar from '../ProgressBar/ProgressBar.vue'

import { detailTitleByIdentifier } from './RatingDetailsSummary.constants'
import translations from './RatingDetailsSummary.translations'

defineProps<{ summary: RatingDetail[] }>()

const i18n = useI18n()

const isCategoriesListDisplayed = ref(false)

const toggleCtaText = computed(() =>
  isCategoriesListDisplayed.value
    ? i18n(translations.showLess)
    : i18n(translations.showMore),
)

const tracking = computed(() => ({
  name: isCategoriesListDisplayed.value
    ? 'see_less_rating_categories'
    : 'see_more_rating_categories',
  zone: TRACKING_ZONE,
}))

function toggleCategories() {
  isCategoriesListDisplayed.value = !isCategoriesListDisplayed.value
}
</script>
