import { createHttpEndpoint } from '../utils'

import type { RatingDistribution } from './types/distributions'
import type { GetReviewsListResponse } from './types/list'
import type { Rating } from './types/rating'
import type { RatingDetail } from './types/review'

/**
 * List of customer reviews for a specific product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-id-reviews}
 */
export const getReviews = createHttpEndpoint<GetReviewsListResponse>({
  method: 'GET',
  operationId: 'fetchReviewsListByProduct',
  path: '/reviews/v1/products/:uuid/reviews',
})

/**
 * Review summary (average rate & number of reviews) for a specific product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-id-reviews-summary}
 */
export const getRating = createHttpEndpoint<Rating>({
  method: 'GET',
  operationId: 'fetchRatingSummaryByProduct',
  path: '/reviews/products/:uuid/reviews-summary',
})

/**
 * Distribution of ratings for a specific product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-id-distributions}
 */
export const getRatingDistribution = createHttpEndpoint<RatingDistribution[]>({
  method: 'GET',
  operationId: 'fetchRatingDistributionByProduct',
  path: '/reviews/products/:uuid/reviews/distributions',
})

/**
 * Average rate of each rating details (batterie, shipping, ...) for a specific product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-id-details-summary}
 */
export const getRatingDetailsSummary = createHttpEndpoint<RatingDetail[]>({
  method: 'GET',
  operationId: 'fetchRatingDetailsSummaryByProduct',
  path: '/reviews/products/:uuid/reviews/details-summary',
})
