import { createHttpEndpoint } from '../utils'

import type { RatingDistribution } from './types/distributions'
import type { GetReviewsListResponse } from './types/list'
import type { Rating } from './types/rating'
import type { RatingDetail } from './types/review'

/**
 * List of customer reviews for a specific product-landing
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product-landing/operation/get-reviews-product-landing-id-reviews}
 */
export const getReviews = createHttpEndpoint<GetReviewsListResponse>({
  method: 'GET',
  operationId: 'fetchReviewsListByLanding',
  path: '/reviews/v1/product-landings/:uuid/products/reviews',
})

/**
 * Review summary (average rate & number of reviews) for a specific product-landing
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product-landing/operation/get-reviews-product-landing-id-reviews-summary}
 */
export const getRating = createHttpEndpoint<Rating>({
  method: 'GET',
  operationId: 'fetchRatingSummaryByLanding',
  path: '/reviews/product-landings/:uuid/products/reviews-summary',
})

/**
 * Distribution of ratings for a specific product-landing
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product-landing/operation/get-reviews-product-landing-id-distributions}
 */
export const getRatingDistribution = createHttpEndpoint<RatingDistribution[]>({
  method: 'GET',
  operationId: 'fetchRatingDistributionByLanding',
  path: '/reviews/product-landings/:uuid/products/reviews/distributions',
})

/**
 * Average rate of each rating details (batterie, shipping, ...) for a specific product-landing
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product-landing/operation/get-reviews-product-landing-id-details-summary}
 */
export const getRatingDetailsSummary = createHttpEndpoint<RatingDetail[]>({
  method: 'GET',
  operationId: 'fetchRatingDetailsSummaryByLanding',
  path: '/reviews/product-landings/:uuid/products/reviews/details-summary',
})
