import { useHead, useRoute, useRuntimeConfig } from '#imports'
import { type Ref, computed } from 'vue'

import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { useAddCanonicalLink } from '~/scopes/seo/composables/useAddCanonicalLink'
import { getMetas } from '~/utils/seo/meta'

import { ROUTES } from '../../route-names'
import translations from '../translations/reviewsHead.translations'

export function useReviewsHead(
  shortTitle: Ref<string | null>,
  ratesResponse: Ref<Rating | null>,
) {
  const route = useRoute()
  const i18n = useI18n()
  const {
    public: { COMPANY_NAME },
  } = useRuntimeConfig()

  const routeName = route.name?.toString() || ''

  const commonParams = computed(() => {
    if (isEmpty(ratesResponse.value) || typeof shortTitle.value !== 'string') {
      return { rate: undefined, count: undefined }
    }

    return {
      rate: ratesResponse.value.averageRate,
      count: ratesResponse.value.count,
    }
  })

  const translationsByRoute = computed(() => ({
    [ROUTES.PAGES.PRODUCT]: {
      title:
        i18n(translations.SEOTitleProduct, {
          ...commonParams.value,
          productName: shortTitle.value,
        }) || '',
      description: i18n(translations.SEODescriptionProduct, {
        ...commonParams.value,
        productName: shortTitle.value,
      }),
    },
    [ROUTES.PAGES.LANDING]: {
      title:
        i18n(translations.SEOTitleListingProduct, {
          ...commonParams.value,
          productName: shortTitle.value,
        }) || '',
      description: i18n(translations.SEODescriptionListingProduct, {
        ...commonParams.value,
        productName: shortTitle.value,
      }),
    },
    [ROUTES.PAGES.MERCHANT]: {
      title:
        i18n(translations.SEOTitleMerchantProducts, {
          ...commonParams.value,
          merchantName: shortTitle.value,
        }) || '',
      description: i18n(translations.SEODescriptionMerchantProducts, {
        ...commonParams.value,
        merchantName: shortTitle.value,
      }),
    },
    [ROUTES.PAGES.ALL]: {
      title: i18n(translations.SEOTitleAllProducts) || '',
      description: i18n(
        translations.SEODescriptionAllProducts,
        commonParams.value,
      ),
    },
  }))

  const title = computed(() => {
    const { title: key } = translationsByRoute.value[routeName]

    return key || ''
  })

  const description = computed(() => {
    const { description: key } = translationsByRoute.value[routeName]

    return key || ''
  })

  const meta = computed(() => {
    return getMetas({
      title: title.value,
      description: description.value,
    })
  })

  useHead({
    title,
    titleTemplate: `%s | ${COMPANY_NAME}`,
    meta,
  })

  useAddCanonicalLink()
}
