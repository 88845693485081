export default {
  titleAllProducts: {
    id: 'reviews_page_all_products_title',
    defaultMessage: 'Customer reviews for all refurbished products',
  },
  titleListingProduct: {
    id: 'reviews_page_listing_products_title',
    defaultMessage: 'Customer reviews for {listing}',
  },
  titleMerchantProducts: {
    id: 'reviews_page_merchant_products_title',
    defaultMessage: "{merchant}'s customer reviews",
  },
  titleProduct: {
    id: 'reviews_page_product_title',
    defaultMessage: "{product}'s customer reviews",
  },
  titlePlaceholder: {
    id: 'reviews_page_title_placeholder',
    defaultMessage: 'Customer reviews',
  },
} as const
